import styled from 'styled-components';
import sizes from '../../../../util/sizes';
import {
  DetailsAvailableHoldOn,
  DetailsAvailableHoldOnContainer,
} from '../../../ProfileHeader/style';

export const MobileProfileSection = styled.div`
  padding: 15px;
  background: linear-gradient(107.48deg, #79abee -12.3%, #1c4d8e 46.24%);
  box-shadow: 0px 4px 16px rgba(25, 73, 135, 0.31);
  border-radius: 16px;
  margin-top: 24px;
  padding-right: 0;
`;

export const MobileDetailsAvailableHoldOn = styled(DetailsAvailableHoldOn)`
  font-size: 15px;
  font-weight: 700;
  &:before {
    top: 6px;
  }
`;

export const MobileDetailsAvailableHoldOnContainer = styled(DetailsAvailableHoldOnContainer)`
  gap: 5px !important;
  align-items: center;
  > div:nth-child(2) {
    margin-top: 0;
  }
`;

export const MobileProfileHeaderLeftSide = styled.div`
  width: inherit;
  > div {
    padding: 10px 0;
  }
  > div:first-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  > div:nth-child(2) {
    padding-left: 20px;
    display: flex;
    gap: 15px;
    align-items: center;
  }
`;

export const MobileProfileHeaderWrapper = styled.div`
  display: none;
  width: calc(100vw - 40px);
  @media (max-width: ${sizes.S}) {
    display: block;
    margin-bottom: 20px;
  }
`;

export const PlusIcon = styled.div`
  position: relative;
  margin-left: 10px;
  &:before {
    content: '';
    width: 15px;
    height: 2px;
    display: block;
    background: #fff;
  }
  &:after {
    content: '';
    width: 2px;
    height: 15px;
    position: absolute;
    left: 6px;
    top: -6px;
    display: block;
    background: #fff;
  }
`;
