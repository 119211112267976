import React, { FC } from 'react';
import Image from 'next/image';

import colors from '@pickles/shared/utils/colors';
import {
  BoxGridHeader,
  BoxGridInfo,
  BoxGridInfoDescription,
  BoxGridInfoTitle,
  BoxGridWrapper,
} from './styles';
import { IMedia } from '../../../util/types';

interface BoxGridProps {
  title: string;
  items: {
    title: string;
    description?: string;
    icon?: IMedia;
  }[];
}

const BoxGrid: FC<BoxGridProps> = ({ title, items }) => {
  const BoxGridInfoElements = items.map((item, index) => {
    const icon = item.icon?.data ? item.icon.data.attributes.url : null;

    return (
      <div key={`market-place-${index}`} style={{ backgroundColor: `${colors.white}` }}>
        {icon && <Image src={icon} width={46} height={46} alt="" />}
        <BoxGridInfoTitle>{item.title}</BoxGridInfoTitle>
        {item.description && <BoxGridInfoDescription>{item.description}</BoxGridInfoDescription>}
      </div>
    );
  });

  return (
    <BoxGridWrapper>
      <BoxGridHeader>{title}</BoxGridHeader>
      <BoxGridInfo>{BoxGridInfoElements}</BoxGridInfo>
    </BoxGridWrapper>
  );
};

export default BoxGrid;
