import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';

export const BoxGridWrapper = styled.div`
max-width: ${sizes.XL};
`;

export const BoxGridHeader = styled.h2`
  margin: 0 auto;
  max-width: ${sizes.XL};
  color: ${colors.rhino_black};
  font-size: 36px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 52px;
  text-align: center;
`;

export const BoxGridInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: ${sizes.XL};

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #d6dadf;
    color: ${colors.san_juan_grey};
    border-radius: 11px;
    padding: 32px;
    text-align: center;
  }

  @media (max-width: ${sizes.L}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${sizes.M}) {
    grid-template-columns: 1fr;
  }
`;

export const BoxGridInfoTitle = styled.h3`
  color: ${colors.rhino_black};
  margin-top: 28px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  max-width: ${sizes.XL};
`;

export const BoxGridInfoDescription = styled.h4`
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${colors.san_juan_grey};
  max-width: ${sizes.XL};
`;
