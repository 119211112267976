import { Account } from '@pickles/shared/models/user.types';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import Link from 'next/link';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DetailsAvailable,
  DetailsHeader,
  DetailsHoldHeader,
} from '../../../ProfileHeader/style';
import { Flex } from '../../Layout/Flex';
import { MobileCreateArrowImg } from '../../MobileAuthBlock/style';
import { Title } from '../../Title';
import {
  MobileDetailsAvailableHoldOn,
  MobileDetailsAvailableHoldOnContainer,
  MobileProfileHeaderLeftSide,
  MobileProfileHeaderWrapper,
  MobileProfileSection,
  PlusIcon,
} from './style';

type PropsType = {
  account: Account;
};

export const MobileProfileHeader: FC<PropsType> = ({ account }) => {
  const { t } = useTranslation();

  return (
    <MobileProfileHeaderWrapper>
      <Title
        text={`${t('labels:welcome')} ${account.lastName},`}
        fontColor={'#334D70'}
        fontSize="32px"
        margin="0 0 2px 0"
        fontWeight={'600'}
      ></Title>
      <Title
        text={t('labels:deals_money')}
        fontColor={'#334D70'}
        fontSize="16px"
        fontFamily={'BasierCircle'}
        margin="0 0 24px 0"
      ></Title>

      <MobileProfileSection>
        <Flex flexDirection="row" gap={'30px'} alignItems="center" justifyContent={'space-between'}>
          <MobileProfileHeaderLeftSide>
            <div>
              <DetailsAvailable>{numberToAmountLabel(account.availableBalance)}</DetailsAvailable>
              <DetailsHeader>Current balance</DetailsHeader>
            </div>
            <MobileDetailsAvailableHoldOnContainer>
              <MobileDetailsAvailableHoldOn>
                {numberToAmountLabel(account.blockedAmount)}
              </MobileDetailsAvailableHoldOn>
              <DetailsHoldHeader>on hold</DetailsHoldHeader>
            </MobileDetailsAvailableHoldOnContainer>
          </MobileProfileHeaderLeftSide>

          <MobileCreateArrowImg isBackgroundArrow={true}>
            <Link href="/profile" passHref>
              <a>
                <PlusIcon />
              </a>
            </Link>
          </MobileCreateArrowImg>
        </Flex>
      </MobileProfileSection>
    </MobileProfileHeaderWrapper>
  );
};
