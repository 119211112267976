import styled from 'styled-components';

import colors from '@pickles/shared/utils/colors';
import BoxGrid from '../Shared/BoxGrid';
import Container from '../Shared/Container';
import sizes from '../../util/sizes';

const Specialization = ({ specialize }: any) => {
  return (
    <SpecializationContainer>
      <BoxGrid items={specialize?.benefit || []} title={specialize?.title} />
    </SpecializationContainer>
  );
};

export default Specialization;

const SpecializationContainer = styled(Container)`
  background-color: ${colors.zircon_light_blue};
  box-shadow: 0 0 0 100vmax ${colors.zircon_light_blue};
  clip-path: inset(0 -100vmax);
  margin-bottom: 60px;
  padding: 80px 0;

  @media (max-width: ${sizes.S}) {
    margin-bottom: 30px;
    padding: 50px 0;
  }
`;
