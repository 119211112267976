import styled from 'styled-components';
import sizes from '../../util/sizes';

export const BrowseInventoryContainer = styled.div`
  width: 100%;
  color: white;
  margin-bottom: 94px;

  @media (max-width: ${sizes.S}) {
    display: none;
    margin-bottom: 0;
  }
`;

interface IBrowseInventoryBackground {
  image: string;
}

export const BrowseInventoryBackground = styled.div<IBrowseInventoryBackground>`
  background: url(${(props) => props.image}) no-repeat;
  background-size: cover;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

export const BrowseInventoryimage = styled.div`
  flex: 1;
  @media (max-width: ${sizes.M}) {
    display: none;
  }
`;

export const BrowseInventoryTextHolder = styled.div`
  padding-left: 93px;
  padding-right: 154px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-basis: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  @media (max-width: ${sizes.L}) {
    padding-left: 53px;
    padding-right: 54px;
    padding: 20px 54px 20px 53px;
  }
`;

export const BrowseInventoryTitle = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
`;

export const BrowseInventoryTagline = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.01em;
  margin-bottom: 20px;
`;
