import Image from 'next/image';
import { FC, useState } from 'react';
import { IMedia } from '../../util/types';
import { SearchBar } from '../Navbar/SearchBar';
import {
  BrowseInventoryBackground,
  BrowseInventoryContainer,
  BrowseInventoryimage,
  BrowseInventoryTagline,
  BrowseInventoryTextHolder,
  BrowseInventoryTitle,
} from './style';

interface BrowseInventoryProps {
  data: {
    title: string;
    tagline: string;
    backgroundImage: IMedia;
    showcaseImage: IMedia;
  };
}

const BrowseInventory: FC<BrowseInventoryProps> = (props) => {
  const [searchKeyword, setSearchKeyword] = useState('');

  if (!props.data) {
    return null;
  }

  const { title, tagline, backgroundImage, showcaseImage } = props.data;

  return (
    <BrowseInventoryContainer>
      <BrowseInventoryBackground image={backgroundImage.data?.attributes.url}>
        <BrowseInventoryimage>
          <Image
            src={showcaseImage.data?.attributes.url}
            width="100%"
            height="55%"
            layout="responsive"
            objectFit="contain"
            alt="image"
          />
        </BrowseInventoryimage>
        <BrowseInventoryTextHolder>
          <BrowseInventoryTitle>{title}</BrowseInventoryTitle>
          <BrowseInventoryTagline>{tagline}</BrowseInventoryTagline>
          <SearchBar
            fullWidth
            placeholder="Search by make or model"
            onChange={(keyword) => {
              setSearchKeyword(keyword);
            }}
            setTouched={() => {}}
            isMinified
            search={searchKeyword}
          />
        </BrowseInventoryTextHolder>
      </BrowseInventoryBackground>
    </BrowseInventoryContainer>
  );
};

export default BrowseInventory;
